<template>
  <div>
    <div style="padding: 10px; display: flex; flex-direction: row">
      <div style="width: calc(100vw - (100vh - 200px)*16/9);">
        <el-card>
          <div slot="header" class="clearfix">
            <span>视频分类</span>
            <el-button v-show="user.role==='SYS_ADMIN'" style="float: right; padding: 3px 0" type="text" @click="dialogVisible=true">管理</el-button>
          </div>
          <div style="max-height: calc(100vh - 300px); overflow-y: auto;">
            <div>
              <el-collapse v-model="activeNames" @change="handleChange">
                <template v-for="(item, ind) in tableData">
                  <el-collapse-item :name="ind">
                    <div slot="title" style="width: 100%; font-size: 18px;">{{ item.name }}</div>
                    <div style="border-radius: 3px; padding: 0 10px">
                      <div v-for="video in item.children" @click="detail(video.url)">

                        <el-popover

                            placement="right"
                            width="200"
                            trigger="hover"
                            >
<!--                          <el-tooltip placement="top" :content="'简介：' + video.descript " width="80px">-->
                            <div>简介：{{ video.descript }}</div>
                            <div slot="reference" class="itemBox" >
                              <div style="font-size: 14px; font-weight: 600; padding: 0 10px;">{{ video.name }}</div>
                              <div style="width: 80px;line-height: 50px;">总时长：</div>
                            </div>
<!--                          </el-tooltip>-->
                        </el-popover>

                      </div>
                    </div>

                  </el-collapse-item>
                </template>
              </el-collapse>
            </div>
          </div>
        </el-card>
      </div>
      <div style="width: calc((100vh - 200px)*16/9);height: calc(100vh - 200px);">
            <video-player class="video-player-box"
                          ref="videoPlayer"
                          :playsinline="true"
                          :options="playerOptions">
            </video-player>
          </div>
    </div>
    <!--  视频文件管理  -->
    <el-dialog :visible.sync="dialogVisible" width="80%" top="10vh">
      <div slot="title">
        <div style="text-align: center; font-size: 28px;">视频文件管理</div>
        <div style="position: absolute; top: 40px; right: 20px;"><el-button type="primary" @click="dialogVisible=false">关闭</el-button></div>
      </div>
      <div style="margin: 10px 0">
        <el-button type="primary" @click="handleAdd(null)">新增一个视频集<i class="el-icon-circle-plus-outline"></i></el-button>
      </div>
      <div  style="height: 500px; overflow-y: auto;">
        <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
                  row-key="id" default-expand-all @selection-change="handleSelectionChange">
<!--          <el-table-column type="selection" width="55"></el-table-column>-->
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="descript" label="视频简介"></el-table-column>
          <el-table-column prop="size" label="文件大小(kb)"></el-table-column>
          <el-table-column prop="duration" label="视频总时长"></el-table-column>
          <el-table-column label="预览">
            <template slot-scope="scope">
              <el-button type="primary" @click="preview(scope.row.url)" v-show="scope.row.pid!==null">预览</el-button>
            </template>
          </el-table-column>
          <el-table-column label="下载">
            <template slot-scope="scope">
              <el-button type="primary" @click="download(scope.row.url)" v-show="scope.row.pid!==null">下载</el-button>
            </template>
          </el-table-column>
          <el-table-column label="启用">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ccc" v-show="scope.row.pid!==null"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template slot-scope="scope">
              <el-button type="primary" @click="handleAddFile(scope.row.id)" v-if="!scope.row.pid && !scope.row.path">新增文件 <i class="el-icon-plus"></i></el-button>
              <el-button type="success" @click="handleEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
              <el-popconfirm
                  class="ml-5"
                  confirm-button-text='确定'
                  cancel-button-text='我再想想'
                  icon="el-icon-info"
                  icon-color="red"
                  title="您确定删除吗？"
                  @confirm="del(scope.row)"
              >
                <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog title="新增一个视频集" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="80px" size="small">
        <el-form-item label="名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.descript" autocomplete="off"></el-input>
        </el-form-item>

<!--        <el-form-item label="描述">-->
<!--          <el-input v-model="form.description" autocomplete="off"></el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增一个视频文件" :visible.sync="dialogAddFormVisible" width="30%" >
      <div class="waitingBox">
        <el-dialog :visible.sync="waitingVisible" append-to-body top="40vh" class="ttt">
          正在上传视频文件，请稍等。。。
        </el-dialog>
      </div>

      <el-form label-width="80px" size="small">
        <el-form-item label="名称">
          <el-input v-model="formFile.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formFile.descript" autocomplete="off"></el-input>
        </el-form-item>
        <div style="padding: 20px;margin: 10px 20px;border: 1px solid #777">
          <div style="margin-bottom: 10px">
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="1" border>上传本地文件</el-radio-button>
              <el-radio-button label="2" border>上传链接文件</el-radio-button>
            </el-radio-group>
          </div>
          <div v-if="radio==='1'">
            <el-upload :action="$baseUrl + '/video/upload'" :headers="{token: user.token}" ref="upload" :on-preview="handlePreview" :limit="1"
                       :on-success="handleFileUploadSuccess" style="display: inline-block" :auto-upload="false" :file-list="fileList"
                       :data="{pid: formFile.pid, name: formFile.name, descript: formFile.descript}">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-upload>
          </div>
          <div v-else>
            <el-form-item label="视频链接">
              <el-input v-model="formFile.url" autocomplete="off"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">保 存</el-button>
<!--        <el-button type="primary" @click="saveFile">确 定</el-button>-->
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVideoVisible" :before-close="handleClose">
      <div v-html="myHtml" ></div>
    </el-dialog>



<!--    <div>-->
<!--      <iframe id="myframe" src="http://localhost:9090/video/ef50821d17aa4eab86d7b8a517cb3369.mp4"></iframe>-->
<!--&lt;!&ndash;      <video width="320" height="240" controls>&ndash;&gt;-->
<!--&lt;!&ndash;        <source src="https://upos-sz-mirrorcos.bilivideo.com/upgcxcode/75/21/488802175/488802175-1-208.mp4?e=ig8euxZM2rNcNbRVhwdVhwdlhWdVhwdVhoNvNC8BqJIzNbfq9rVEuxTEnE8L5F6VnEsSTx0vkX8fqJeYTj_lta53NCM=&uipk=5&nbs=1&deadline=1710605882&gen=playurlv2&os=bcache&oi=1866713016&trid=000074569a636304402aac856f3f19cadb71T&mid=0&platform=html5&upsig=17401bea31c318cae4e49bf17687a667&uparams=e,uipk,nbs,deadline,gen,os,oi,trid,mid,platform&cdnid=3290&bvc=vod&nettype=0&bw=48029&orderid=0,3&buvid=&build=0&mobi_app=&f=T_0_0&logo=80000000" type="video/mp4">&ndash;&gt;-->
<!--&lt;!&ndash;        Your browser does not support the video tag.&ndash;&gt;-->
<!--&lt;!&ndash;      </video>&ndash;&gt;-->
<!--    </div>-->
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
export default {
  name: "Video",
  components: {
    videoPlayer
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogFormVisible: false,
      dialogVideoVisible: false,
      dialogAddFormVisible: false,
      waitingVisible: false,
      myHtml:  '',
      fileList: '',
      form: {},
      formFile: {},
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      radio: '1',
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: require("@/assets/logo1.png"), // 封面地址
        notSupportedMessage: '', //'此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  created() {
    this.request.get("/video/findAll").then(res => {
      console.log("调入所有文件：", res.data)
      // this.videos = res.data.filter(v => v.type === 'mp4')
      // this.playerOptions.sources[0].src = this.videos.length === 0 ? '' : this.videos[0].url
      this.tableData = res.data
    })
 //   this.load()
  },
  methods: {
    // load() {
    //   this.request.get("/video/page", {
    //     params: {
    //       pageNum: this.pageNum,
    //       pageSize: this.pageSize,
    //       name: this.name,
    //     }
    //   }).then(res => {
    //     console.log(res.data.records)
    //     this.tableData = res.data.records
    //     this.total = res.data.total
    //
    //   })
    // },
    detail(url) {
      // alert(url)
     this.playerOptions.sources[0].src = url
     this.playerOptions.poster = ''
    },
    handleAdd(pid) {
      this.dialogFormVisible = true
      this.form = {}
      if (pid) {
        this.form.pid = pid
      }
    },
    handleAddFile(pid) {
      this.dialogAddFormVisible = true
      this.radio = '1'
      this.formFile = {}
      console.log(this.formFile.url)
      // if (pid) {
        this.formFile.pid = pid
      // }
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    save() {

      this.request.post("/video", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()

        } else {
          this.$message.error("保存失败")
        }
      })
    },
    del(row) {
      if(row.pid===null && row.children.length!==0){
          this.$message.error("有视频文件！不能删除！")
      }else {
        this.request.delete("/video/" + row.id).then(res => {
          if (res.code === '200') {
            this.$message.success("删除成功")
            this.tableData = res.data

          } else {
            this.$message.error("删除失败")
          }
        })
      }
    },
    saveFile() {

      this.request.post("/video", this.formFile).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false

          this.fileList = ''
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    submitUpload() {

      if(this.radio==='1'){
        this.waitingVisible = true
        this.$refs.upload.submit();
        this.fileList = ''
      }else {
        this.saveFile()
      }

    },
    handleFileUploadSuccess(res) {
      console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>",res.data)
      this.tableData=res.data
      this.$message.success("上传成功")
      this.dialogAddFormVisible = false
      this.waitingVisible = false
    },
    download(url) {
      window.open(url)
    },
    preview(url) {
      this.dialogVideoVisible=true
      // window.open(url, "newwindows","width=500, height=500, left=0, top=0");
      // window.open( url, '_blank');
      this.myHtml="<video controls autoplay src=" + url + " style=\"width: 100%; height:100%\"/>"
      //this.myHtml="<video controls autoplay src=" + this.$baseUrl + "/video/video" + url.substring(url.lastIndexOf('/')) + " style=\"width: 100%; height:100%\"/>"
    },
    handleClose(done){  // 关闭视频预览
      this.myHtml =''
      done()
    },
  }
}
</script>

<style>
.itemBox{
  width: 100%;
  margin:2px;
  padding: 2px;
  height: 50px;
  background: orange;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
}
.itemBox:hover{
  color: #3a8ee6;
  font-weight: 700;
  cursor: pointer;
  /*height: 80px;*/
  /*background: none;*/
}

.headerBg {
  background: #eee!important;
}

/*  对话框el-dialog样式修改 */
.waitingBox .el-dialog {
  width: 400px!important;
  border-radius: 10px;
  /*background-color: orange!important;*/
}
.ttt .el-dialog__header {
  display: none;
  /*padding: 10px;*/
  /*color: white;*/
  /*caret-color: transparent;*/
}
.ttt .el-dialog__body {
  font-size: 20px;
  padding: 20px;
  color: black;
  /*border-radius: 20px;*/
  background-color: orange!important;
  caret-color: transparent;
}
.waitingBox .el-dialog__footer {
  display: none;
}
.ttt .el-dialog__headerbtn {
  display: none;
}
</style>
